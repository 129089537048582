header,
footer
  display: flex
  height: var( --header-size )
  background: var( --header-background )
  color: var( --header-color )

  h1
    flex: 1
    font-size: var( --3x )
    line-height: var( --header-size )
    padding: 0 var( --2x )
    text-transform: uppercase

  h2
    flex: 1
    font-size: var( --3x )
    line-height: var( --header-size )

  button
    opacity: 0.8
    min-width: var( --header-size )
    height: var( --header-size )
    color: inherit

    font-size: 140%

    &:hover
      opacity: 1

  select
    flex: 1
    appearance: none
    border: 0
    background: inherit
    font-size: var( --3x )
    text-transform: uppercase
    padding: 0 var( --2x )
    color: inherit
