table
  display: flex
  flex-direction: column

  thead,
  tbody

    tr
      flex: 1
      display: flex
      min-height: var( --header-size )

      td
        flex: 1

        display: flex
        align-items: center

        padding: 0 var( --gap )

        a
          color: var( --color )

        button
          height: var( --header-size )
          width: var( --header-size )

          &:hover
            background: rgba( white, 0.2 )

        &.button
          flex: 0

        &.actions
          flex: none
          padding: 0
          min-width: var( --header-size )

          &.x2
            min-width: calc( var( --header-size ) * 2 )

          &.x3
            min-width: calc( var( --header-size ) * 3 )

          &.x4
            min-width: calc( var( --header-size ) * 4 )

          &.x5
            min-width: calc( var( --header-size ) * 5 )

          &.x6
            min-width: calc( var( --header-size ) * 6 )

          &.x7
            min-width: calc( var( --header-size ) * 7 )

          &.select-ctrl
            label
              width: 100%
              text-align: center

  thead
    background: var( --table-header-background )
    color: var( --table-header-color )
    border-top-left-radius: var( --border-radius )
    border-top-right-radius: var( --border-radius )

    tr

      td
        font-weight: bold

  tbody

    tr

      &:nth-child( even )
        background: var( --table-row-even )

      &:hover
        background: var( --table-row-hover )

  tfoot

  & + .pager
    background: var( --table-card-background )
    display: flex
    height: var( --header-size )
    line-height: var( --header-size )
    font-size: 95%
    border-bottom-left-radius: var( --border-radius )
    border-bottom-right-radius: var( --border-radius )

    .info
      padding: 0 var( --gap )

    .ipp
      flex: 1
      display: flex
      justify-content: flex-end

      span
        padding: 0 var( --gap )

      select
        appearance: none
        border: none
        padding: 0 var( --gap )
        background: transparent
        color: var( --color )
        cursor: pointer

        &:hover
          background: rgba( white, 0.1 )

    .actions

      button
        width: var( --header-size )
        height: var( --header-size )
        font-size: 140%

        &:disabled
          cursor: not-allowed
          opacity: 0.7

.table
  flex: 1

  &.full
    overflow-y: hidden

    table
      overflow-y: auto

      tbody
        overflow-y: auto
