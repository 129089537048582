aside
  background: var( --aside-background )
  color: var( --aside-color )
  width: var( --aside-size )
  height: 100vh
  display: flex
  flex-direction: column
  align-items: center

  header
    width: 100%

    .logo
      width: 100%
      background-size: var( --logo-size )
      background-position: var( --logo-position )

    .icon
      width: 100%
      background-image: var( --icon )
      background-repeat: no-repeat
      background-position: center
      background-size: var( --icon-size )

  section
    width: 100%
    flex: 1

    nav
      ul
        li
          display: flex
          outline: none

          a
            flex: 1
            color: var( --color )
            text-decoration: none

            &.active
              color: var( --accent )

            &:hover
              background: var( --aside-nav-hover-background )
              outline: none

    ul
      flex: 1
      width: 100%

      li
        height: var( --header-size )
        line-height: var( --header-size )
        padding: 0

        button
          width: var( --header-size )
          height: var( --header-size )

        span
          font-size: 120%

          &.icon
            display: inline-block
            width: var( --header-size )
            height: var( --header-size )
            font-size: 130%
            text-align: center

        &:hover
          background: rgba( white, 0.05 )

  footer
    width: 100%

  &.minified
    width: var( --header-size )

    header
      .logo
        background-image: var( --icon )
        background-size: 77%

        h1
          display: none

    section
      ul
        li
          span:not( [class=icon] )
            display: none

  &.x49
    width: calc( 49% - var( --header-size ) )

  &.x42
    width: calc( 42% - var( --header-size ) )

  &.x35
    width: calc( 35% - var( --header-size ) )
