.row
  flex-wrap: wrap
  display: flex

  &.centered
    display: flex
    justify-content: center

  & + .row
    margin-top: var( --gap )
