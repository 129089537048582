.toast-list
  position: fixed
  top: 0
  right: 0
  padding: var( --gap )
  max-height: 100vh

  display: flex
  flex-direction: column-reverse
  gap: var( --gap )
  flex-wrap: wrap-reverse

  .toast
    border-radius: calc( var( --gap ) * 0.3 )
    cursor: pointer

    header
      background: inherit
      color: inherit

      .icon
        width: var( --header-size )
        height: var( --header-size )

        display: flex
        justify-content: center
        align-items: center

        font-size: var( --4x )

    section
      padding: var( --gap )
      background: inherit

    &.success
      background: var( --success-background )
      color: var( --success-color )

    &.error
      background: var( --error-background )
      color: var( --error-color )

    &.info
      background: var( --info-background )
      color: var( --info-color )

    &.warning
      background: var( --warning-background )
      color: var( --warning-color )
