.inline-controls

  height: var( --header-size )

  .select
    height: 100%

    .placeholder
      height: var( --header-size )
      line-height: var( --header-size )

    section
      label
        height: var( --header-size )
        line-height: var( --header-size )
