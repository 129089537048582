.title,
.subtitle
  display: flex
  height: var( --header-size )
  align-items: center

  h1
    flex: 1
    font-size: 110%
    line-height: var( --header-size )
    padding: 0 var( --2x )
    text-transform: uppercase

  svg
    font-size: 147%
    width: var( --header-size )

* + .title
  margin-top: calc( var( --gap ) * 1.4 )

.subtitle
  h1
    font-size: 91%
    line-height: calc( var( --header-size ) * 0.84 )

  svg
    font-size: 100%
    width: calc( var( --header-size ) * 0.84 )

* + .subtitle
  margin-top: calc( var( --gap ) * 0.7 )
