.app-icons
  flex: initial
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: center

  gap: var( --gap )
  padding: var( --gap )

  .app-icon
    background: var( --card-background )
    padding: var( --gap )
    border-radius: var( --gap )

    width: 150px
    height: 150px

    cursor: pointer

    .app-logo
      height: 120px
      display: flex
      justify-conten: center
      align-items: center

      img
        width: 100%

    label
      background: pink
      display: none

    &:hover
      transform: scale( 1.07 )

    // @media (max-width: 900px)
