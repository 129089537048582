.modal
  background: var( --modal-transparency )
  width: 100%
  height: 100vh

  position: absolute
  top: 0
  left: 0

  display: flex
  justify-content: center
  align-items: center

  main
    flex: 0 0 25%
    height: auto
    max-height: 70vh

    background: var( --modal-background )
    color: var( --modal-color )
    z-index: 1

  &.x50
    main
      flex: 0 0 50%

  &.x70
    main
      flex: 0 0 70%
