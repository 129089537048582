body
  font-family: "Open Sans", sans-serif
  background: var( --background )
  color: var( --color )

#root
  display: flex
  width: 100%

button
  background: transparent
  color: var( --color )
  border: 1px solid transparent
