main
  flex: 1
  display: flex
  flex-direction: column
  height: 100vh

  background: rgba( white, 0.03 )
  // background: var( --background )

  section
    flex: 1
    overflow-y: auto
