\:root
  --base: 7px

  --1x: var( --base )
  --2x: calc( var( --base ) * 2 )
  --3x: calc( var( --base ) * 3 )
  --4x: calc( var( --base ) * 4 )
  --5x: calc( var( --base ) * 5 )
  --6x: calc( var( --base ) * 6 )
  --7x: calc( var( --base ) * 7 )

  --aside-size: calc( var( --base ) * 42 )
  --header-size: var( --7x )

  --gap: var( --2x )

  --transparency: #2b3748

  --border-radius: calc( var( --gap ) * 0.42 )

  --loader-size: calc( var( --base ) * 21 )

  // // root
  // --logo: url('data:image/png;base64,...)
  // --background: white
  // --color: black

  // --accent: rgb( 25, 118, 210 )

  // // header
  // --header-background: rgba( 25, 118, 210, 0.08 )
  // --header-color: black

  // // aside
  // --aside-background: rgba( 25, 118, 210, 0.08 )
  // --aside-color: black

  // // card
  // --card-background: rgba( 25, 118, 210, 1 )
  // --card-color: white

  // // graph card
  // --graph-card-background: rgba( 25, 118, 210, 0.08 )
  // --graph-card-color: black

  // // table
  // --table-card-background: rgba( 25, 118, 210, 0.08 )
  // --table-card-color: black
  // --table-header-background: rgba( 25, 118, 210, 0.18 )
  // --table-header-color: inherit
  // --table-row-even: rgba( 148, 221, 255, 0.7 )
  // --table-row-hover: rgba( 148, 221, 255, 0.3 )

  // // modal
  // --modal-transparency: rgba( 25, 118, 210, 0.42 )
  // --modal-background: white
  // --modal-color: black

  // // input
  // --input-background: rgba( 25, 118, 210, 0.14 )
  // --input-color: black
  // --input-select-background: #b5d4f2
