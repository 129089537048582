.card
  background: var( --card-background )
  color: var( --card-color )

  &.graph
    background: var( --graph-card-background )
    color: var( --graph-card-color )

  &.table
    background: var( --table-card-background )
    color: var( --table-card-color )
