.loader
  flex: 1
  font-size: 140%
  text-align: center
  animation: animloader 1s linear infinite alternate

@keyframes animloader

  0%
    opacity: 0.7

  75%
    opacity: 1

.loader-wrap
  background: rgba( black, 0.91 )
  position: fixed
  top: 0
  left:0
  z-index: 1

  width: 100%
  height: 100vh

  display: flex
  justify-content: center
  align-items: center

  .ripple
    display: inline-block
    position: relative
    width: var( --loader-size )
    height: var( --loader-size )

    div
      position: absolute
      border: 4px solid #fff
      opacity: 1
      border-radius: 50%
      animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite

      &:nth-child(2)
        animation-delay: -0.5s

@keyframes ripple
  0%
    top: calc( var( --loader-size ) * 0.5 )
    left: calc( var( --loader-size ) * 0.5 )
    width: 0
    height: 0
    opacity: 1
  100%
    top: 0px
    left: 0px
    width: var( --loader-size )
    height: var( --loader-size )
    opacity: 0

.spin
  animation: spin 1s ease infinite

@keyframes spin
  0%
    transform: rotate( 360deg )
    opacity: 0.7

  49%
    opacity: 0.5

  100%
    transform: rotate( 0deg )
    opacity: 1
