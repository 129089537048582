input
  border: 0
  background: var( --input-background )
  color: var( --input-color )

  height: var( --header-size )
  padding: 0 var( --gap )

  &.giant
    height: auto
