form
  .row
    gap: var( --gap )
    padding: var( --gap )

    .form-group
      flex: 1
      display: flex
      flex-direction: column

      label
        text-transform: capitalize

        & + *
          margin-top: calc( var( --gap ) * 0.5 )

      input
        height: calc( var( --header-size ) * 0.7 )

    & + .row
      margin: 0

    &.one
      .form-group
        flex: 0 0 calc( 100% - var( --gap ) * 0.7 )
        width: calc( 100% - var( --gap ) * 0.7 )

    &.one-third

      .form-group
        flex: 0 0 calc( 33.33% - var( --gap ) * 0.7 )
        width: calc( 33.33% - var( --gap ) * 0.7 )

      @media (max-width: 900px)

        .form-group
          flex: 0 0 calc( 100% - var( --gap ) * 0.7 )
          width: calc( 100% - var( --gap ) * 0.7 )

    &.one-two

      .form-group
        flex: 0 0 calc( 50% - var( --gap ) * 0.7 )
        width: calc( 50% - var( --gap ) * 0.7 )

      @media (max-width: 900px)

        .form-group
          flex: 0 0 calc( 100% - var( --gap ) * 0.7 )
          width: calc( 100% - var( --gap ) * 0.7 )

  &.filters
    display: flex
    flex-wrap: wrap

    gap: var( --gap )
    padding: var( --gap )

    .form-group
      flex: 0 0 calc( 50% - var( --gap ) * 0.7 )
      width: calc( 50% - var( --gap ) * 0.7 )

      display: flex
      flex-direction: column

      input
        height: calc( var( --header-size ) * 0.7 )

    @media (max-width: 900px)

      .form-group
        flex: 0 0 calc( 100% - var( --gap ) * 0.7 )
        width: calc( 100% - var( --gap ) * 0.7 )
