.i-am-here
  svg
    animation: here 0.5s ease-in-out infinite alternate

@keyframes here
  0%
    transform: rotate( 28deg )

  100%
    transform: rotate( -28deg )
