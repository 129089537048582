footer
  display: flex

  height: auto
  justify-content: space-between

  button
    display: flex
    justify-content: center
    align-items: center
