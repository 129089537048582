.select
  display: flex
  flex-direction: column

  position: relative

  .placeholder
    height: calc( var( --header-size ) * 0.7 )
    line-height: calc( var( --header-size ) * 0.7 )
    padding: 0 var( --gap )
    background: var( --input-background )
    color: var( --input-color )
    cursor: pointer

    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

  .options
    display: none
    position: absolute
    top: 0
    right: 0
    min-width: calc( var( --base ) * 42 )
    background: var( --input-select-background )
    color: var( --input-select-color )
    z-index: 1
    border: 1px solid var( --input-select-background )

    section
      display: flex
      flex-direction: column
      gap: unset

      label
        line-height: calc( var( --header-size ) * 0.7 )

        padding: 0 var( --gap )

        input
          margin-right: calc( var( --gap ) * 0.5 )

          &[type=radio]
            display: none
            appearance: none
            background: inherit

        &:nth-child( even )
          background: var( --header-background )

        &:hover
          background: var( --table-row-hover )

    &.open
      display: block

  &.full
    .options
      width: 100%

form
  .row

    &.one

    &.one-third
      .form-group
        &:first-child
          .select
            .options
              left: 0

      @media (max-width: 900px)
        .form-group
          .select
            .options
              left: 0

    &.one-two
      .form-group
        &:first-child
          .select
            .options
              left: 0

      @media (max-width: 900px)
        .form-group
          .select
            .options
              left: 0

  &.filters

    .form-group:nth-child( odd )
      .select
        .options
          left: 0

    @media (max-width: 900px)
      .form-group
        .select
          .options
            left: 0
