.powered-by
  display: flex
  flex-direction: column
  align-items: center

  cursor: pointer

  padding: var( --gap )
  filter: invert( 33% )
  font-size: 0.8em

  img
    width: calc( var( --header-size ) * 1.2 )

    &.minified
      width: calc( var( --header-size ) * 0.8 )

  & > * + *
    margin-top: calc( var( --gap ) * 0.3 )
