section
  &.placeholder
    display: flex
    flex-direction: column

    font-size: var( --4x )
    color: var( --background )
    filter: invert( 33% )

    svg
      font-size: var( --5x )

      & + *
        margin-left: var( --gap )

    &.centered
      justify-content: center
      align-items: center
