.autocomplete

  display: flex
  flex-direction: column

  position: relative

  .options
    background: var( --background )
    position: absolute

    top: calc( var( --header-size ) * 0.7 )
    left: 0
    min-width: 100%
    z-index: 99999

    ul
      z-index: 9999
      li
        line-height: calc( var( --header-size ) * 0.7 )
        white-space: nowrap
        cursor: pointer

        &.selected
          background: var( --table-row-hover )
