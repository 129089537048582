.balert
  display: flex
  flex-direction: column

  .icon
    display: flex
    justify-content: center
    align-items: center

    width: var( --7x )

    font-size: var( --5x )

  .message
    flex: 1
    display: flex
    flex-direction: column

    b
      font-size: var( --3x )

    i
      font-style: italic
      font-size: 0.9em

  .buttons
    display: flex
    flex-direction: row
    gap: var( --gap )

    margin-top: var( --gap )

    button
      flex: 1

      display: flex
      align-items: center
      justify-content: center
      gap: var( --gap )

      font-size: var( --3x )

      opacity: 0.7

      svg

      &:hover
        opacity: 1

      &.yes
        background: var( --accent )
        color: white

      &.no
        background: rgba( 66, 66, 66, 0.07 )
