.composed-input
  background: var( --input-background )
  color: var( --input-color )
  gap: 0

  input
    background: transparent

  button
    width: var( --header-size )
    height: var( --header-size )
    font-size: var( --3x )
    opacity: 0.7

    &:hover
      opacity: 1

    &.full
      width: 100%

  .icon
    width: var( --header-size )
    height: var( --header-size )
    font-size: var( --3x )

    display: flex
    justify-content: center
    align-items: center
