.fade-check
  background: rgba( #e4572e, 0.3 )
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

  cursor: pointer

  display: flex
  justify-content: center
  align-items: center

  svg
    opacity: 0
    font-size: var( --7x )

  &:hover

    svg
      opacity: 1

  &.checked
    background: transparent

    &:hover
      background: rgba( #e4572e, 0.09 )
