// background
.bg-accent
  background: var( --accent )

.bg-charcoal
  background: var( --color-charcoal )

.bg-flame
  background: var( --color-flame )

.bg-gray
  background: var( --color-gray )

.bg-yellow
  background: var( --color-yellow )

.bg-lime
  background: var( --color-lime )

.bg-electric-blue
  background: var( --color-electric-blue )

.bg-acid-green
  background: var( --color-acid-green )

.bg-blue-bell
  background: var( --color-blue-bell )

.bg-cornflower-blue
  background: var( --color-cornflower-blue )

.bg-gold-fusion
  background: var( --color-gold-fusion )

.transparent
  background: transparent !important

// color
.color-accent
  color: var( --accent )

.color-charcoal
  color: var( --color-charcoal )

.color-flame
  color: var( --color-flame )

.color-gray
  color: var( --color-gray )

.color-yellow
  color: var( --color-yellow )

.color-lime
  color: var( --color-lime )

.color-electric-blue
  color: var( --color-electric-blue )

.color-acid-green
  color: var( --color-acid-green )

.color-blue-bell
  color: var( --color-blue-bell )

.color-cornflower-blue
  color: var( --color-cornflower-blue )

.color-gold-fusion
  color: var( --color-gold-fusion )

.o-10p
  opacity: 0.1

.o-20p
  opacity: 0.2

.o-30p
  opacity: 0.3

.o-40p
  opacity: 0.4

.o-50p
  opacity: 0.5

.o-60p
  opacity: 0.6

.o-70p
  opacity: 0.7
